import styles from './App.module.scss';

function App() {
  return (
    <div className={styles.main}>
      <h1>RAWSTAKE</h1>
      <h2>Elevating staking to the next level</h2>
      <h3>Coming soon...</h3>
      <p>Contact: info@rawstake.io</p>
    </div>
  );
}

export default App;